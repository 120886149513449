import { Controller } from "@hotwired/stimulus";
import mixpanel from "mixpanel-browser";

// Should be used directly on the tab element itself in the HTML
// Connects to data-controller="copy-paste"
export default class extends Controller {
  copyInput(event) {
    const stringToCopy = event.params.input;
    const fadeOutAlertMethod = this.fadeOutAlert;
    setTimeout(async () => {
      // The use of navigator.clipboard requires a secure origin
      if (window.isSecureContext && navigator.clipboard) {
        await navigator.clipboard.writeText(stringToCopy).then(
          function () {
            if (!(window === null) && !(window.document === null)) {
              const globalAlert =
                window.document.getElementById("global-alert");
              if (!(globalAlert === null)) {
                globalAlert.textContent = "Copied to clipboard";
                globalAlert.classList.toggle("d-none");
                fadeOutAlertMethod(globalAlert, 2000);
              }
            }
          },
          function (err) {
            console.error("Failed to write to clipboard: ", err);
          },
        );
      }
    });
    const contextParamsJson = event.params.contextParams; // json blob of additional context params
    mixpanel.track("Clicked Copy", contextParamsJson);
  }

  fadeOutAlert(alert, fadeTime) {
    const fadeIntervalTime = 20;
    const fadeIntervals = fadeTime / fadeIntervalTime;
    const fadeIncrement = 1.0 / fadeIntervals;
    const fadeEffect = setInterval(function () {
      if (!alert.style.opacity) {
        alert.style.opacity = 1;
      }
      if (alert.style.opacity > 0) {
        alert.style.opacity -= fadeIncrement;
      } else {
        alert.classList.add("d-none");
        alert.style.opacity = 1;
        clearInterval(fadeEffect);
      }
    }, fadeIntervalTime);
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  toggleDetails(event) {
    event.preventDefault();
    const parentEl = event.currentTarget.parentElement;
    const expandedClass = "dataset-card--expanded";
    if (parentEl.classList.contains(expandedClass)) {
      parentEl.classList.remove(expandedClass);
    } else {
      parentEl.classList.add(expandedClass);
    }
  }

  requestInfo(event) {
    event.preventDefault();
  }
}
